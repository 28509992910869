<template>
  <div>
    <HeaderBack />
    <section class="un-brand-about margin-t-10">
      <img class="brand_img light-mode" src="images/logo_b.svg" alt="" />
      <img class="brand_img dark-mode" src="images/logo-white.svg" alt="" />
      <p>Version 1.0.0 - Copyright</p>
    </section>

    <section class="about-section">
      <div class="descriptio">
        <h2>What we offer</h2>
        <p>We try to give best dapps store for all investor over the world</p>
      </div>
    </section>

    <section class="copyright-mark margin-t-40">
      <div class="content">
        <img class="logo-gray" src="images/logo-gray.svg" alt="image" />
        <p class="margin-b-20">Copyright © Dino dApps 2022</p>
      </div>
      <ul class="nav social-networks-footer">
        <a class="nav-link facebook" href="javascript: void(0)">
          <i class="ri-facebook-circle-fill"></i>
        </a>
        <a class="nav-link instagram" href="javascript: void(0)">
          <i class="ri-instagram-fill"></i>
        </a>
        <a class="nav-link youtube" href="javascript: void(0)">
          <i class="ri-youtube-fill"></i>
        </a>
        <a class="nav-link twitter" href="javascript: void(0)">
          <i class="ri-twitter-fill"></i>
        </a>
      </ul>
    </section>
  </div>
</template>

<script>
import HeaderBack from "@/components/HeaderBack.vue";

export default {
  name: "About",
  components: {
    HeaderBack,
  },
  created() {
    this.$mixpanel.track("Visit About ");
  },
};
</script>

<style></style>
